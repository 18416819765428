import React, { useState } from 'react'
import {
  Stack,
  Heading,
  Input,
  Button,
  FormControl,
  FormLabel,
  Flex,
  Text,
  Spinner,
  Link,
  FormErrorMessage,
} from '@chakra-ui/react'
import { Link as GatsbyLink } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import ForgotPassword from '../features/user/forgot-password'
import ResetPassword from '../features/user/reset-password'

const LoginPage = () => {
  const [showResetForm, setShowResetForm] = useState(false)

  return (
    <Layout>
      <SEO title="Password Recovery" />
      <Stack width="100%" maxWidth="md" spacing={5}>
        <Heading textAlign="center">
          {showResetForm ? 'Reset Password' : 'Forgot Password?'}
        </Heading>

        <Stack mt={5} borderWidth="1px" rounded="md" py={3} px={5}>
          {showResetForm ? <ResetPassword /> : <ForgotPassword handleResetFormClick={() => setShowResetForm(true)} />}
        </Stack>
        <Flex alignItems="center" mt={5}>
          <Link as={GatsbyLink} to="/login">
            Login
          </Link>
          <Text mx={6}>•</Text>
          <Link as={GatsbyLink} to="/signup">
            Sign up
          </Link>
        </Flex>
      </Stack>
    </Layout>
  )
}

export default LoginPage
