import React, { useState } from 'react'
import {
  Stack,
  Input,
  Button,
  FormControl,
  FormLabel,
  Flex,
  Text,
  Spinner,
  Link,
  FormErrorMessage,
} from '@chakra-ui/react'
import { Link as GatsbyLink } from 'gatsby'
import { useMachine } from '@xstate/react'
import { Auth } from 'aws-amplify'
import { navigate } from '@reach/router'
import { fetchMachine, events } from '../../utils/fetch.machine'
import { useForm } from 'react-hook-form'

const resetPassword = (ctx, evt) => {
  const { email, code, newPassword } = evt.payload

  return Auth.forgotPasswordSubmit(email, code, newPassword)
}

const ResetPassword = () => {
  const [currentState, send] = useMachine(fetchMachine, {
    services: {
      fetchData: resetPassword,
    },
    actions: {
      onSuccess: () => {
        navigate('/dashboard')
      },
    },
  })

  const { register, handleSubmit, formState: { errors } } = useForm()

  const onSubmit = payload => {
    send({
      type: events.FETCH,
      payload
    })
  }

  const isLoading = currentState.matches('loading')
  const hasError = currentState.matches('error')
  const hasSuccess = currentState.matches('success')

  return hasSuccess ? (
    <Stack>
      <Text bg="green.200" borderColor="green.900" color="green.800" p="5">
        Success! Your password has been reset.
      </Text>

      <Link as={GatsbyLink} mt={3} to="/login">
        Click here to login with your new credentials.
      </Link>
    </Stack>
  ) : (
    <FormControl
      as="form"
      isInvalid={hasError}
      my={6}
      onSubmit={handleSubmit(onSubmit)}
    >
      <FormLabel htmlFor="email">Email</FormLabel>
      {errors.email && (
        <Text color="red.300" fontSize="xs">
          {errors.email.message}
        </Text>
      )}
      <Input
        mb={4}
        size="lg"
        {...register('email', {
          required: 'Email is required', pattern: {
            value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            message: 'Invalid email address'
          }
        })}
      />
      <FormLabel htmlFor="code">Reset Code</FormLabel>
      {errors.code && (
        <Text color="red.300" fontSize="xs">
          {errors.code.message}
        </Text>
      )}
      <Input
        mb={4}
        size="lg"
        {...register('code', {
          required: 'Please enter your reset code'
        })}
      />
      <FormLabel htmlFor="newPassword">New Password</FormLabel>
      {errors.newPassword && (
        <Text color="red.300" fontSize="xs">
          {errors.newPassword.message}
        </Text>
      )}
      <Input
        mb={4}
        size="lg"
        type="password"
        {...register('newPassword', {
          required: 'Password required',
          minLength: {
            value: 6,
            message: 'Must be at least 6 characters'
          }
        })}
      />
      <FormErrorMessage>
        {currentState.context.error}
        {Object.keys(errors).map(error => {
          return <Text key={error}>{errors[error]}</Text>
        })}
      </FormErrorMessage>
      <Flex justifyContent="flex-end">
        <Button mt={5} type="submit" colorScheme="teal">
          {isLoading ? <Spinner /> : 'Change Password'}
        </Button>
      </Flex>
    </FormControl>
  )
}

export default ResetPassword
