import React from 'react'
import {
  Input,
  Button,
  FormControl,
  FormLabel,
  Flex,
  Text,
  Spinner,
  FormErrorMessage,
} from '@chakra-ui/react'
import { useMachine } from '@xstate/react'
import { Auth } from 'aws-amplify'
import { fetchMachine, events } from '../../utils/fetch.machine'
import { useForm } from 'react-hook-form'

const forgotPassword = (ctx, evt) => {
  const { email } = evt.payload

  return Auth.forgotPassword(email)
}

const ForgotPassword = ({ handleResetFormClick }) => {
  const [currentState, send] = useMachine(fetchMachine, {
    services: {
      fetchData: forgotPassword,
    },
  })

  const { register, handleSubmit, formState: { errors } } = useForm()

  const onSubmit = payload => {
    send({
      type: events.FETCH,
      payload,
    })
  }

  const isLoading = currentState.matches('loading')
  const hasSuccess = currentState.matches('success')
  const hasError = currentState.matches('error')

  return hasSuccess ? (
    <Text my={12} textAlign="center">
      Please check your email for your recovery code
    </Text>
  ) : (
    <FormControl
      as="form"
      isInvalid={hasError}
      my={6}
      onSubmit={handleSubmit(onSubmit)}
    >
      <FormLabel htmlFor="email">Email</FormLabel>
      {errors.email && (
        <Text color="red.300" fontSize="xs">
          {errors.email.message}
        </Text>
      )}
      <Input
        mb={4}
        size="lg"
        type="text"
        {...register('email', {
          required: 'Email is required', pattern: {
            value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            message: 'Invalid email address'
          }
        })}
      />
      <Flex alignItems="center" justifyContent="flex-end" mb={4}>
        <Button onClick={handleResetFormClick}>
          Have your reset code?
        </Button>
      </Flex>
      <FormErrorMessage>
        {currentState.context.error}
      </FormErrorMessage>
      <Flex justifyContent="flex-end">
        <Button mt={5} type="submit" colorScheme="teal" width="100%">
          {isLoading ? <Spinner /> : 'Email Me'}
        </Button>
      </Flex>
    </FormControl >
  )
}

export default ForgotPassword
